import { graphql, navigate } from "gatsby"
import React, { useMemo, useState } from "react"
import Layout from "../../components/layout/layout"
import SEOnew from "../../components/seo-new"
import { PrismicRichText } from "@prismicio/react"
import htmlSerializer from "../../utils/htmlSerializer"
import SlideContent from "../../components/sections/HomeFeaturedSection/SlideContent"
import useWindowSize from "../../utils/useWindowSize"
import ContactUsCompact from "../../components/sections/ContactUsCompact"
import CommitmentSignature from "../../components/sections/CommitmentSignature"
import { HiArrowLeft } from "react-icons/hi"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"

// import required modules
import { Navigation, Autoplay } from "swiper/modules"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"

const FunDetailsPage = ({ data }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const prismicFunAtBJAData = data.prismicFunAtBja.data
  const screen = useWindowSize()
  const gallery = useMemo(() => {
    const images = []

    prismicFunAtBJAData.gallery.forEach(gallery => {
      if (gallery.image.fluid) {
        images.push(gallery.image.fluid.src)
      }
    })

    return images
  }, [prismicFunAtBJAData])

  return (
    <Layout isHome={true}>
      <SEOnew title={prismicFunAtBJAData.title} />
      <div
        className="w-full flex items-center justify-center relative bg-site-gray overflow-hidden"
        style={{
          height: "100px",
        }}
      >
        <div
          className="absolute"
          style={{
            //backgroundImage: `url(${prismicPortfolios.allPrismicBasePage.edges[0].node.data.hero_image.fluid.src})`,
            backgroundSize: "cover",
            top: 0,
            right: 0,
            height: "100%",
            width: "50%",
            filter: "brightness(1)",
            backgroundPosition: "25% 75%",
          }}
        >
          &nbsp;
        </div>
        <div
          className="absolute top-0 left-0 w-full h-full"
          style={{
            backgroundColor: "transparent",
            backgroundImage:
              "linear-gradient(to left, #FFFFFF00, #33333310 25%, #333333F0 40%)",
          }}
        >
          &nbsp;
        </div>
      </div>
      <div className="w-full flex justify-center py-8">
        <div className="portfolio-details w-full max-w-5xl flex flex-col items-center justify-center">
          <div className="text-3xl py-4 mb-12 text-center">
            {prismicFunAtBJAData.title}
          </div>
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            loop={true}
            slidesPerView={1}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[Navigation, Autoplay]}
            className="portfolio-swiper"
          >
            {gallery.map((img, index) => {
              return (
                <SwiperSlide
                  key={`portfolio-details-gallery-${index}`}
                  className="portfolio-swiper-slide relative"
                >
                  <StaticImage
                    id="cover"
                    src="../../images/blank.png"
                    quality={95}
                    placeholder="DOMINANT_COLOR"
                    formats={["AUTO", "WEBP"]}
                    alt={`portfolio-details-gallery-${index}`}
                    className="w-full h-full z-5 top-0 left-0"
                    objectFit="cover"
                    style={{position: "absolute"}}
                  />
                  <img
                    src={img}
                    loading="lazy"
                    style={{
                      width: "auto",
                      height: "100%",
                    }}
                  />
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <PrismicRichText
            field={prismicFunAtBJAData.project_details_content.richText}
            components={htmlSerializer}
          />
          <div className="w-full pt-12">
            <div
              className="flex items-center gap-x-2 cursor-pointer max-w-max"
              onClick={() => {
                navigate("/fun")
              }}
            >
              <HiArrowLeft />
              <p className="text-sm">Back to Fun</p>
            </div>
          </div>
        </div>
      </div>
      <ContactUsCompact />
      <CommitmentSignature />
    </Layout>
  )
}

export const funTemplateQuery = graphql`
  query($id: String!) {
    prismicFunAtBja(id: { eq: $id }) {
      uid
      data {
        title
        location
        preview_image {
          gatsbyImageData
        }
        gallery {
          image {
            fluid {
              src
            }
          }
        }
        project_details_content {
          richText
        }
      }
    }
  }
`

export default FunDetailsPage
